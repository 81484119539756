import { alpha, styled, useTheme } from '@mui/material';
// ? Material Ui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// ? Next js
import Image from 'next/image';
import React from 'react';
import { API } from '_helpers';

const Logos = styled(Paper)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
}));

interface IPartners {
  title: string;
  description: string;
  list: IImageItem[];
}

const Partners: React.FC<IProps<IPartners>> = ({ data }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: '100vh',
        bgcolor: 'background.paper',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          bgcolor: (theme) =>
            theme.palette.mode == 'dark'
              ? alpha(theme.palette.primary.main, 0.1)
              : 'grey.A100',
          width: '100%',
          height: {
            xs: 600,
            md: 200,
          },
          position: 'absolute',
          zIndex: 0,
        }}
      ></Box>
      <Box
        sx={{
          width: '100%',
          height: {
            xs: 600,
            md: 200,
          },
          position: 'absolute',
          zIndex: 0,
          top: {
            xs: 600,
            md: 200,
          },
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill={
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.primary.main, 0.1)
                : theme.palette.grey.A100
            }
            fillOpacity={1}
            d="M0,128L80,144C160,160,320,192,480,202.7C640,213,800,203,960,213.3C1120,224,1280,256,1360,272L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          />
        </svg>
      </Box>

      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Container>
          <Stack sx={{ px: { xs: 0, md: 12, lg: 20 }, py: 10 }} spacing={2}>
            <Typography
              component="h2"
              color="white"
              variant="h2"
              textAlign="center"
              gutterBottom
            >
              {data.title}
            </Typography>
            <Typography color="white" textAlign="center" gutterBottom>
              {data.description}
            </Typography>
          </Stack>
          <Item elevation={2} sx={{ p: { xs: 4, md: 6 } }}>
            <Grid container spacing={2}>
              {data.list.map((el, i) => (
                <Grid item xs={6} sm={4} md={2} key={el._id}>
                  <Logos sx={{ p: 2 }} variant="outlined">
                    <Image
                      alt={el.media.alternativeText}
                      src={`${API}${el.media.url}`}
                      width={el.media.width}
                      height={el.media.height}
                      priority
                    />
                  </Logos>
                </Grid>
              ))}
            </Grid>
          </Item>
        </Container>
      </Box>
    </Box>
  );
};

export default Partners;
